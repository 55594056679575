import {useContext} from "react";
import {context} from "../Router/AppRouter.component";



const ComplaintsTemplate = ({children}) => {

    const {clientData, setClientData} = useContext(context);

    return (<>
        {
            clientData != null &&
            <div className="w-100 bg-light" style={{minHeight: "100vh"}}>
                <nav className="navbar sticky-top navbar-light"
                     style={{minHeight: "100px", backgroundColor: clientData.complainChannelConfig.color}}>
                    <div className="mx-5">
                        <a className="navbar-brand" href="#">
                            <img src={clientData.logo} height="50"
                                 className="d-inline-block align-top" alt=""/>
                        </a>
                    </div>
                </nav>
                <div className="container h-100" style={{paddingBottom: "150px"}}>
                    {children}
                </div>
                <footer className="footer py-3 bg-dark text-white-50 w-100 position-fixed fixed-bottom">
                    <div className="container text-center">
                        <small>
                            Sistema creado por:
                            <span className="px-2">
                            <img src="https://osacontrol.com/osa_control_logo.svg" height="20px"
                                 style={{marginBottom: "10px"}}/>
                        </span>
                            &
                            <span className="px-2">
                            <img src="/images/1.0.png" height="20px" style={{marginBottom: "5px"}}/>
                        </span>
                        </small>
                    </div>
                </footer>
            </div>
        }
    </>);
}

export default ComplaintsTemplate;