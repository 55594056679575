import AdminTemplate from "../../Templates/Admin.template";
import {MENU_ITEMS} from "../../Utils/menu";
import {StatusEnum} from "../../Utils/utils";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import HttpClient from "../../Utils/HttpClient";
import withReactContent from "sweetalert2-react-content";
import CreateCompanyModal from "../../Components/CreateCompany.modal";

const CompanyPage = () => {

    const [status, setStatus] = useState(StatusEnum.LOADING);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        let client = new HttpClient();
        client.getCompanies()
            .then(response => {
                let companies = response.data.companies
                setCompanies(companies)
                setStatus(companies.length > 0 ? StatusEnum.SUCCESS : StatusEnum.EMPTY)
            })
            .catch(error => {
                console.error(error)
                setStatus(StatusEnum.ERROR)
            })
    }, []);



    const showCreateCompanyModal = async () => {

        let response = await withReactContent(Swal).fire({
            title: "Crear empresa",
            html: <CreateCompanyModal addCompany={(company) => {
                setCompanies([...companies, company])
            }} />,
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
            preConfirm(data) {
                console.log(data)
                return data;
            }
        });
    }

    const showEditCompany = async (company) => {
        let response = await withReactContent(Swal).fire({
            title: "Crear empresa",
            html: <CreateCompanyModal updateCompany={(company) => {
                let datum = companies.filter(c => c._id !== company._id)
                setCompanies([...datum, company])
            }} company={company} />,
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
            preConfirm(data) {
                console.log(data)
                return data;
            }
        });
    }


    return <AdminTemplate page={MENU_ITEMS.COMPANY_PAGE}>
        <div className="row justify-content-center align-content-center w-100">
            <div className="row mt-5 mb-3">
                <h1 className="title text-center"> Empresas</h1>
            </div>

            <div className="row justify-content-end my-5 ">
                <button className="btn btn-primary col-2" onClick={showCreateCompanyModal}>
                    Crear Empresa
                </button>
            </div>

            <div className="col-sm-12 col-md-12">
                <div className="card" style={{minHeight: "200px"}}>
                    <div  className="card-header">
                        <span className="text card-title h4">Empresas</span>
                    </div>
                    <div className="card-body position-relative" >
                        {status === StatusEnum.LOADING &&
                            <div className="d-flex justify-content-center align-content-center mt-5">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>

                        }
                        {status === StatusEnum.ERROR &&
                            <div className="alert alert-danger" role="alert">
                                Error al cargar las empressas
                            </div>
                        }
                        {status === StatusEnum.EMPTY &&
                            <div className="alert alert-info" role="alert">
                                No hay empresas registradas
                            </div>
                        }
                        {status === StatusEnum.SUCCESS &&
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Logo</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Unidades</th>
                                    <th scope="col">Fecha creación</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                {companies.map((company, index) => (
                                    <tr key={index}>
                                        <th scope="row">
                                            <img src={company.logo} alt={company.name} className="img-fluid" style={{maxWidth: "100px", backgroundColor: company.complainChannelConfig.color}}/>
                                        </th>
                                        <th>{company.name}</th>
                                        <td>
                                            {company.complainChannelConfig.units && company.complainChannelConfig.units.map((unit, index) => (
                                                <li key={index}>{unit}</li>
                                            ))}
                                        </td>
                                        <td>{new Date(company.createdAt).toLocaleDateString("es-CL")}</td>
                                        <td>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" onClick={() => showEditCompany(company)}
                                                        className="btn btn-primary mx-3" data-toggle="modal"
                                                        data-target="#exampleModal">Editar
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </div>
    </AdminTemplate>

}

export default CompanyPage;