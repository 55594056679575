const SingleSelectionControl = ({question, options, setValue, value, key} ) => {

    return <div className="form-control">
        <label htmlFor="caseType">{question}</label>
        {options.map((option, index) =>
            <div className="form-check">
                <input className="form-check-input" type="radio" name={key}
                       checked={value === option.value}
                       value={option.value}
                       onChange={(event) => {
                           setValue(event.target.value);
                       }}/>
                <label className="form-check-label" htmlFor="anonymousCase">
                    {option.label}
                </label>
            </div>
        )}
    </div>

}

export default SingleSelectionControl;