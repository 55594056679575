import {getClientData, getSlugFromURL} from "../Utils/utils";
import {useEffect, useState} from "react";
import AdminSideMenu from "../Components/AdminSideMenu.component";

const AdminTemplate = (props) => {

    const [clientData, setClientData] = useState(getClientData())

    useEffect(() => {
        document.title = "Admin - Sistema de Denuncias";
    }, []);



    return (
        <div className="w-100 bg-light" style={{minHeight: "100vh"}}>
            <div className="d-flex w-100 min-vh-100 align-content-stretch">

                <AdminSideMenu selected={props.page}/>

                <div className="flex-grow-1">
                    <nav className="navbar sticky-top navbar-light"
                         style={{minHeight: "100px", backgroundColor: "#8b1d42"}}>
                        <div className="mx-5">
                            <a className="navbar-brand" href="#">
                                <img src="https://osacontrol.com/osa_control_logo.svg" height="50"
                                     className="d-inline-block align-top" alt=""/>
                            </a>
                        </div>
                    </nav>
                    <div className="container">
                        <div className="d-flex">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer py-3 bg-dark text-white-50 w-100 position-fixed fixed-bottom">
                <div className="container text-center">
                    <small>
                        Sistema creado por:
                        <span className="px-2">
                            <img src="https://osacontrol.com/osa_control_logo.svg" height="20px"
                                 style={{marginBottom: "10px"}}/>
                        </span>
                        &
                        <span className="px-2">
                            <img src="/images/1.0.png" height="20px" style={{marginBottom: "5px"}}/>
                        </span>
                    </small>
                </div>
            </footer>
        </div>
    );
}

export default AdminTemplate;