import HttpClient from "./HttpClient";

export const CLIENTS_MOCK = {
    "demo": {
        "name": "Demo",
        "slug": "",
        "logo": "https://osacontrol.com/osa_control_logo.svg",
        "complainChannelConfig": {
            "units": [
                "Ejemplo 1",
                "Ejemplo 2",
                "Ejemplo 3"
            ],
            "color": "#8b1d42",
        }
    },
}

export const CLIENTS = Object.keys(CLIENTS_MOCK);
export const DEFAULT_CLIENT = "demo";

export const getSlugFromURL = () => {
    const url = window.location.href;
    let slug = url.split('-')
    if (slug.length === 1) {
        return DEFAULT_CLIENT;
    }
    return slug[0].split("/").reverse()[0];
}

export const getClientData = (slug) => {
    if (sessionStorage.getItem(slug)) {
        return JSON.parse(sessionStorage.getItem(slug));
    } else {
        return CLIENTS_MOCK[slug];
    }
}

export const StatusEnum = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
    EMPTY: 'empty',
}

export const COMPLAINT_STATUS_DICT = {
    "pending": "Pendiente",
    "closed": "Cerrado",
    "investigating": "Investigando",
    "resolved": "Resuelto"
}