import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import HttpClient from "../../Utils/HttpClient";
import {COMPLAINT_STATUS_DICT, StatusEnum} from "../../Utils/utils";
import AdminTemplate from "../../Templates/Admin.template";
import {MENU_ITEMS} from "../../Utils/menu";
import ComplaintInfo from "../../Components/ComplaintInfo.modal";
import withReactContent from "sweetalert2-react-content";

const ComplaintsPage = (props) => {

    const [complaints, setComplaints] = useState([]);
    const [status, setStatus] = useState(StatusEnum.LOADING);

    useEffect(() => {
        let client = new HttpClient();
        client.getComplaints()
            .then(response => {
                console.log(response)
                const complaints = response.data.complaints;
                setComplaints(complaints)
                setStatus(complaints.length > 0 ? StatusEnum.SUCCESS : StatusEnum.SUCCESS)
            }).catch(error => {
                console.log(error)
            })
    }, []);

    const displayComplaintInfo = (complaint) => {
        withReactContent(Swal).fire({
            title: "Información del caso",
            html: <ComplaintInfo complaint={complaint}/>,
            icon: "info",
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonText: "Cerrar",

        });
    }

    const resolveComplaint = (complaint) => {
        withReactContent(Swal).fire({
            title: "Información del caso",
            html: <ComplaintInfo complaint={complaint} isEdit={true} isAdmin={true} updateFunc={updateComplaints}/>,
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,

        });
    }

    const updateComplaints = (complaint) => {
        let tmp = complaints.filter((c) => c._id !== complaint._id);
        setComplaints([...tmp, complaint]);
    }

    return <AdminTemplate page={MENU_ITEMS.COMPLAIN_PAGE}>
        <div className="row justify-content-center align-content-center w-100">
            <div className="row mt-5 mb-3">
                <h1 className="title text-center"> Consulta de denuncias</h1>
            </div>

            <div className="col-sm-12 col-md-12">
                <div className="card" style={{minHeight: "420px"}}>
                    <div  className="card-header">
                        <span className="text card-title h4">Denuncias</span>
                    </div>
                    <div className="card-body position-relative" >
                        {status === StatusEnum.LOADING &&
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                        {status === StatusEnum.ERROR &&
                            <div className="alert alert-danger" role="alert">
                                Error al cargar las denuncias
                            </div>
                        }
                        {status === StatusEnum.EMPTY &&
                            <div className="alert alert-info" role="alert">
                                No hay denuncias registradas
                            </div>
                        }
                        {status === StatusEnum.SUCCESS &&
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Unidad de negocio</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {complaints.map((complaint, index) => {
                                    return <tr key={index}>
                                        <th scope="row">{complaint.businessUnit}</th>
                                        <td>{complaint.type}</td>
                                        <td>{new Date(complaint.createdAt).toLocaleDateString("es-CL")}</td>
                                        <td>{COMPLAINT_STATUS_DICT[complaint.status]}</td>
                                        <td>
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" onClick={() => displayComplaintInfo(complaint)}
                                                        className="btn btn-primary mx-3" data-toggle="modal"
                                                        data-target="#exampleModal">Ver
                                                </button>
                                                <button type="button" onClick={() => resolveComplaint(complaint)}
                                                        className="btn btn-success">Resolver
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </div>
    </AdminTemplate>
}

export default ComplaintsPage;
