import ComplaintsTemplate from "../Templates/Complaints.template";
import {ReCAPTCHA} from "react-google-recaptcha";
import DropDownFileComponent from "../Components/DropDownFile.component";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {toFormData} from "axios";
import HttpClient from "../Utils/HttpClient";
import Button from "bootstrap/js/src/button";
import Swal from "sweetalert2";
import {getClientData, getSlugFromURL, StatusEnum} from "../Utils/utils";
import {context} from "../Router/AppRouter.component";

const CreateComplaintPage = (props) => {

    const [selectedAccordion, setSelectedAccordion] = useState(1);
    const [showOtherRelation, setShowOtherRelation] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(true);
    const [data, setData] = useState({});
    const navigate = useNavigate()

    const value = useContext(context);
    let clientData
    let setClientData;
    if (value) {
        clientData = value.clientData;
        setClientData = value.setClientData;
    }

    useEffect(() => {
        document.title = "Sistema de Denuncias";
    }, []);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    }

    const BACKGROUND_COLOR = "#57abdf22";

    const handleFileChange = (files) => {
        setData({
            ...data,
            files
        })
    }

    const validateForm = () => {
        let flag = true;
        console.log(isAnonymous, data);
        if (!isAnonymous){
            if (!data.name || !data.email || !data.phone || !data.contactTime){
                flag = false;
                Swal.fire({
                    title: "Error",
                    text: "Por favor llena todos los campos de información de contacto",
                    icon: "error"
                });
            }
        }

        if (!data.type || !data.businessUnit || (!data.relationship && !data.otherRelation) || !data.description){
            flag = false;
            Swal.fire({
                title: "Error",
                text: "Por favor llena todos los campos de información del caso",
                icon: "error"
            });
        }
        return flag;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm(event)){
            return;
        }
        let formData = new FormData();

        if (!isAnonymous) {
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("phone", data.phone);
            formData.append("contactTime", data.contactTime);
        }

        formData.append("company", clientData.name);

        formData.append("type", data.type);
        formData.append("businessUnit", data.businessUnit);
        formData.append("relationship", data.relationship || data.otherRelation);

        formData.append("description", data.description);

        if (data.files){
            data.files.forEach((file) => {
                formData.append("files", file);
            });
        }

        let client = new HttpClient();

        client.createComplaint(formData).then((response) => {
            console.log(response);
            let data = response.data.complaint;
            navigate("/success", {
                state: {
                    ...data
                }
            });

        }).catch((error) => {
            Swal.fire({
                title: "Error",
                text: "Ocurrió un error al crear el caso",
                icon: "error"
            });
            console.error(error);
        })

    }

    return <ComplaintsTemplate state={StatusEnum.SUCCESS}>
        {clientData != null &&
            <div className="row justify-content-center h-100">
                <div className="col-sm-12 col-md-8">
                    <h1 className="title mt-5">Nuevo Caso</h1>
                    {clientData.slug === "portland" &&
                        <div className="my-3">
                            <Link to={"/karin/complaint/"}>Crear caso en relacion a Ley Karin (Ley °21.643)</Link>
                        </div>
                    }
                    <form onSubmit={handleSubmit}>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                            style={selectedAccordion === 1 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(1)}  >
                                        Información de contacto
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="form-control">
                                            <label htmlFor="caseType">¿Quieres hacer tu denuncia de forma anónima?</label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="type"
                                                        id="anonymousCase" checked={data.type === "anónimo"}
                                                        value={"anónimo"}
                                                        onChange={(event) =>{
                                                            setIsAnonymous(event.target.checked);
                                                            handleChange(event);
                                                        }}/>
                                                <label className="form-check-label" htmlFor="anonymousCase">
                                                    Sí
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="type"
                                                        id="nonAnonymousCase" value="no anónimo"
                                                        checked={data.type === "no anónimo"}
                                                        onChange={(event) =>{
                                                                setIsAnonymous(!event.target.checked);
                                                                handleChange(event);
                                                            }}
                                                />
                                                <label className="form-check-label" htmlFor="nonAnonymousCase">
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        { !isAnonymous &&
                                            <div>
                                                <div className="form-control">
                                                    <label htmlFor="caseType">Nombre completo</label>
                                                    <input type="text" className="form-control" id="caseType"
                                                           aria-describedby="caseTypeHelp" name="name" value={data.name}
                                                           onChange={handleChange} placeholder="Nombre completo"
                                                    />
                                                    <small id="caseTypeHelp" className="form-text text-muted">Ingresa tu
                                                        nombre completo</small>
                                                </div>
                                                <div className="form-control">
                                                    <label htmlFor="caseType">Correo electrónico</label>
                                                    <input type="email" className="form-control" id="caseType"
                                                           aria-describedby="caseTypeHelp" name="email" value={data.email}
                                                           onChange={handleChange} placeholder="Correo electrónico"
                                                    />
                                                    <small id="caseTypeHelp" className="form-text text-muted">Ingresa tu
                                                        correo electrónico</small>
                                                </div>
                                                <div className="form-control">
                                                    <label htmlFor="caseType">Teléfono</label>
                                                    <input type="tel" className="form-control" id="caseType"
                                                           aria-describedby="caseTypeHelp" name="phone" value={data.phone}
                                                           onChange={handleChange} placeholder="Teléfono"
                                                    />
                                                    <small id="caseTypeHelp" className="form-text text-muted">Ingresa tu
                                                        número de teléfono</small>
                                                </div>
                                                <div className="form-control">
                                                    <label htmlFor="caseType">Horario preferido para ser contactado</label>
                                                    <select className="form-select" aria-label="Default select example"
                                                            name="contactTime" value={data.contactTime} onChange={handleChange}
                                                    >
                                                        <option value="">Selecciona un horario</option>
                                                        <option value="Mañana (08:00 a 12:00)">Mañana (08:00 a 12:00)</option>
                                                        <option value="Tarde (12:00 a 18:00)">Tarde (12:00 a 18:00)</option>
                                                        <option value="Noche (18:00 a 20:00)">Noche (18:00 a 20:00)</option>
                                                    </select>
                                                </div>

                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                            style={selectedAccordion === 2 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(2)}>
                                        Relación con la unidad de negocio
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <div className="form-control">
                                            <label htmlFor="caseType">¿Tipo de caso?</label>
                                            <select className="form-select" aria-label="Default select example"
                                                    name="type" value={data.type} onChange={handleChange}
                                            >
                                                <option>Selecciona un tipo de caso</option>
                                                <option value="Consulta">Consulta</option>
                                                <option value="Denuncia">Denuncia</option>
                                            </select>
                                        </div>

                                        <div className="form-control">
                                            <label htmlFor="caseType">¿Unidad de negocio desde donde consulta?</label>
                                            <select className="form-select" aria-label="Default select example"
                                                    name="businessUnit" value={data.businessUnit} onChange={handleChange}
                                            >
                                                <option>Selecciona una unidad de negocio</option>
                                                {clientData.complainChannelConfig.units.map((option, index) => {
                                                    return <option key={index} value={option}>{option}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="form-control">
                                            <label htmlFor="caseType">¿Cual es tu relación con esta unidad de
                                                negocio?</label>
                                            <select className="form-select" aria-label="Default select example"
                                                    name="relationship" value={data.relationship}
                                                onChange={(event) => {
                                                    if (event.target.value === "") {
                                                        setShowOtherRelation(true);
                                                    } else {
                                                        handleChange(event);
                                                        setShowOtherRelation(false);
                                                    }
                                                }}>
                                                <option>Selecciona una relación</option>
                                                <option value="Cliente">Cliente</option>
                                                <option value="Colaborador">Colaborador</option>
                                                <option value="Proveedor">Proveedor</option>
                                                <option value="">Otro</option>
                                            </select>
                                            {showOtherRelation &&
                                                <div className="mt-3">
                                                    <label htmlFor="caseType">Especifica</label>
                                                    <input type="text" className="form-control" id="caseType"
                                                           aria-describedby="caseTypeHelp" name="otherRelation"
                                                           value={data.otherRelation} onChange={handleChange}
                                                    />
                                                </div>
                                            }
                                        </div>


                                        </div>
                                    </div>
                                </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={selectedAccordion === 3 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(3)} >
                                        Detalles del caso
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="form-control my-3">
                                            <label htmlFor="caseDescription">Descripción</label>
                                            <textarea className="form-control" id="caseDescription"
                                                      aria-describedby="caseDescriptionHelp" style={{minHeight: "300px"}}
                                                        name="description" value={data.description} onChange={handleChange}
                                            />
                                            <small id="caseDescriptionHelp" className="form-text text-muted">Ingresa una
                                                descripción
                                                detallada de tu caso</small>
                                        </div>
                                        {/*<div className="form-control">*/}
                                        {/*    <label htmlFor="">Adjunte archivos que respalden su consulta</label>*/}
                                        {/*    <div className="my-3">*/}
                                        {/*        <DropDownFileComponent onFilesChange={handleFileChange}/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ReCAPTCHA
                                sitekey="6LdAErYpAAAAAC5pAcvyMAhGgclHv5qFvLKUWiw8"
                            />
                        </div>
                        <div className="row justify-content-end">
                            <button type="submit" className="btn btn-primary mt-3">Crear caso</button>
                        </div>
                    </form>
                </div>
            </div>
        }
    </ComplaintsTemplate>
}

export default CreateComplaintPage;