import {Link} from "react-router-dom";

const AdminSideMenu = ({selected}) => {

    let items = [
        {name: "Denuncias", url: "/admin/complaints", subItems: []},
        {name: "Empresas", url: "/admin/companies", subItems: []},
    ];

    return (
        <div className="d-flex flex-column flex-shrink-0" style={{width: "200px", backgroundColor: "#8b1d42"}}>

            <div className="w-100 py-4 px-3" style={{height: "100px"}}>
                <a href="/admin/complaints">
                    <img src="https://osacontrol.com/osa_control_logo.svg" alt="OSA logo" height="50" />
                </a>

            </div>
            <div className="flex-grow-1" style={{backgroundColor: "#222d32"}}>
                <ul className="list-unstyled px-3">
                    {
                        items.map((item) => {
                            return (
                                <li className="mb-1" key={item.name}>
                                    <Link to={item.url}
                                       className={"btn btn-toggle d-inline-flex align-items-center rounded border-0 " + (selected === item.name ? "text-light" : "text-white-50")}>
                                        {item.name}
                                    </Link>
                                </li>
                            )

                        })
                    }





                    {/*<li className="mb-1">*/}
                    {/*    <button*/}
                    {/*        className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed text-light"*/}
                    {/*        data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">*/}
                    {/*        Home*/}
                    {/*    </button>*/}
                    {/*    <div className="collapse show" id="home-collapse">*/}
                    {/*        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">*/}
                    {/*            <li><a href="#"*/}
                    {/*                   className="link-body-emphasis d-inline-flex text-decoration-none rounded text-light">Overview</a>*/}
                    {/*            </li>*/}
                    {/*            <li><a href="#"*/}
                    {/*                   className="link-body-emphasis d-inline-flex text-decoration-none rounded">Updates</a>*/}
                    {/*            </li>*/}
                    {/*            <li><a href="#"*/}
                    {/*                   className="link-body-emphasis d-inline-flex text-decoration-none rounded">Reports</a>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</li>*/}

                </ul>

            </div>


        </div>
    );
}

export default AdminSideMenu;