import HttpClient from "../Utils/HttpClient";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";

const CreateCompany = (props) => {

    const [company, setCompany] = useState({
        name: "",
        slug: "",
        logo: "",
        color: "",
        areas: [""]
    });

    useEffect(() => {
        if (props.company){
            console.log(props.company)
            let datum = {
                name: props.company.name,
                slug: props.company.slug,
                logo: props.company.logo,
                color: props.company.complainChannelConfig.color,
                areas: props.company.complainChannelConfig.units
            }
            setCompany(datum)
        }
    }, [props.company]);

    const handleChange = (e) => {
        setCompany({...company, [e.target.name]: e.target.value})
    }


    const handleChangeArea = (e, index) => {
        let areas = [...company.areas];
        areas[index] = e.target.value;
        setCompany({...company, areas: areas})
    }

    const deleteArea = (index) => {
        let areas = [...company.areas];
        areas.splice(index, 1);
        setCompany({...company, areas: areas})
    }

    const addArea = () => {
        let areas = [...company.areas];
        areas.push("");
        setCompany({...company, areas: areas})
    }

    const createCompany = async () => {
        let client = new HttpClient();
        let data = {
            name: company.name,
            slug: company.slug,
            logo: company.logo,
            complainChannelConfig: {
                color: company.color,
                units: company.areas
            }
        }


        client.createCompany(data)
            .then(response => {
                let newCompany = response.data.company;
                props.addCompany(newCompany)
                Swal.fire({
                    title: "Empresa creada",
                    icon: "success",
                    text: "La empresa ha sido creada exitosamente"
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Ha ocurrido un error al crear la empresa"
                })

            })
    }

    const updateCompany = async () => {
        let client = new HttpClient();

        let data = {
            name: company.name,
            slug: company.slug,
            logo: company.logo,
            complainChannelConfig: {
                color: company.color,
                units: company.areas
            }
        }

        client.updateCompany(props.company._id, data)
            .then(response => {
                let newCompany = response.data.company;
                props.updateCompany(newCompany)
                Swal.fire({
                    title: "Empresa actualizada",
                    icon: "success",
                    text: "La empresa ha sido actualizada exitosamente"
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Ha ocurrido un error al actualizar la empresa"
                })

            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.company){
            updateCompany();
        } else {
            createCompany(company);
        }
    }

    return (
        // Boostrap form to create a company with following inputs name, slug, logo URL, main color, areas of the company (should have an add button in case of more than one and two buttons to submit or cancel
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label className="form-label">Nombre</label>
                <input type="text" name="name" className="form-control" value={company.name} onChange={handleChange} required/>
            </div>
            <div className="form-group">
                <label className="form-label">Slug</label>
                <input type="text" name="slug" className="form-control" value={company.slug} onChange={handleChange} required/>
            </div>
            <div className="form-group">
                <label className="form-label">Areas</label>
                {company.areas.map((area, index) => {
                    return <div key={index} className="d-flex justify-content-between mt-2">
                        <input type="text" name="areas"
                               className={"form-control " + (company.areas.length > 1 ? "w-75" : "w-100")}
                               onChange={(e) => handleChangeArea(e, index)} value={company.areas[index]} required/>
                        {company.areas.length > 1 &&
                            <button type="button" onClick={() => deleteArea(index)}
                                    className="btn rounded-circle btn-danger">
                                -
                            </button>
                        }
                    </div>
                })}
                <div className="d-flex justify-content-end my-2">
                    <button type="button" className="btn btn-primary" onClick={addArea}>Agregar area</button>
                </div>
            </div>
            <div className="form-group">
                <label className="form-label">Logo URL</label>
                <input type="text" name="logo" className="form-control" value={company.logo} onChange={handleChange} required/>
            </div>
            <div className="form-group">
                <label className="form-label">Color principal</label>
                <input type="text" name="color" className="form-control" value={company.color} onChange={handleChange} required/>
            </div>
            <div className="d-flex justify-content-around mt-5">
                <button type="button" className="btn btn-danger">Cancelar</button>
                <button type="submit" className="btn btn-primary">{props.company ? "Actualizar" : "Crear"}</button>
            </div>
        </form>
    )

}

export default CreateCompany;