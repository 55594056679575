

const PersonalInfo = ({data, setData}) => {

    return <div>
        { data.nombre != undefined && <div className="form-control">
            <label htmlFor="caseType">Nombre completo</label>
            <input type="text" className="form-control" id="caseType"
                   aria-describedby="caseTypeHelp" name="nombre_completo" value={data.nombre}
                   onChange={(e) => setData(e.target.value, "nombre")} placeholder="Nombre completo"
            />
            <small id="caseTypeHelp" className="form-text text-muted">Ingresa tu nombre completo</small>
        </div>}
        { data.rut != undefined && <div className="form-control">
            <label htmlFor="caseType">RUT</label>
            <input type="text" className="form-control" id="caseType"
                   aria-describedby="caseTypeHelp" name="rut" value={data.rut}
                   onChange={(e) => setData(e.target.value, "rut")} placeholder="11111111-K"
            />
            <small id="caseTypeHelp" className="form-text text-muted">Ingresa el rut sin puntos y con guión</small>
        </div>}
    { data.email != undefined && <div className="form-control">
            <label htmlFor="caseType">Correo electrónico</label>
            <input type="email" className="form-control" id="caseType"
                   aria-describedby="caseTypeHelp" name="email" value={data.email}
                   onChange={(e) => setData(e.target.value, "email")} placeholder="Correo electrónico"
            />
            <small id="caseTypeHelp" className="form-text text-muted">Ingresa tu
                correo electrónico</small>
        </div>}
        { data.cargo != undefined && <div className="form-control">
            <label htmlFor="caseType">Cargo</label>
            <input type="text" className="form-control" id="caseType"
                   aria-describedby="caseTypeHelp" name="cargo" value={data.cargo}
                   onChange={(e) => setData(e.target.value, "cargo")} placeholder="Cargo"
            />
            <small id="caseTypeHelp" className="form-text text-muted">Ingresa el cargo</small>
        </div>}
            { data.unidad != undefined && <div className="form-control">
            <label htmlFor="caseType">Departamento/Gerencia/Área</label>
            <input type="text" className="form-control" id="caseType"
                   aria-describedby="caseTypeHelp" name="unidad" value={data.unidad}
                   onChange={(e) => setData(e.target.value, "unidad")} placeholder="Unidad a la cual pertenece"
            />
        </div>}
    </div>

}


export default PersonalInfo;