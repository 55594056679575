import ComplaintsTemplate from "../../Templates/Complaints.template";
import {useState} from "react";
import HttpClient from "../../Utils/HttpClient";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {

    const [data, setData] = useState({})
    const navigate = useNavigate()

    const sendLoginRequest = async () => {
        const httpClient = new HttpClient();
        httpClient.login(data.email, data.password)
            .then(response => {
                console.log(response)
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                navigate('/admin/complaints/')
            }).catch(error => {
                console.log(error)
            })
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

        return <ComplaintsTemplate>
            <div className="row justify-content-center h-100 align-content-center">
                <div className="row mt-5 mb-3 justify-content-center">
                    <div className="col-md-4">
                        <form className="form-signin form-control">
                            <h1 className="h1 mb-3 font-weight-normal text-center">OSA América denuncias </h1>
                            <div className="my-2 mx-2">
                                <label htmlFor="inputEmail" className="sr-only">Email address</label>
                                <input type="email" id="inputEmail" className="form-control" placeholder="Email address"
                                       value={data.email} onChange={handleChange} name="email"
                                       required autoFocus/>
                            </div>
                            <div className="my-2 mx-2">
                                <label htmlFor="inputPassword" className="sr-only">Password</label>
                                <input type="password" id="inputPassword" className="form-control" placeholder="Password"
                                       required value={data.password} onChange={handleChange} name="password"/>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                                <button className="btn btn-primary btn-block" type="button" onClick={sendLoginRequest}>Entrar</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </ComplaintsTemplate>
}

export default LoginPage;