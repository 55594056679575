import ComplaintsTemplate from "../Templates/Complaints.template";
import {Link, useLocation} from "react-router-dom";
import {StatusEnum} from "../Utils/utils";

const SuccessPage = (props) => {

    const location = useLocation();
    const complaint = location.state;

    const print = () => {
        window.print();
    }

    return <ComplaintsTemplate state={StatusEnum.SUCCESS}>
        <div className="row justify-content-center align-content-center h-100">
            <div className="col-sm-12 col-md-6">
                <h1 className="title mt-5 mb-3">¡Tu caso ha sido creado con éxito!</h1>
                <div className="card mt-3" style={{minHeight: "350px"}}>
                    <div className="card-header">
                        <span className="text card-title">Detalles de tu caso</span>
                    </div>
                    <div className="card-body position-relative">
                        <p className="text">Tu caso ha sido creado con éxito, la contraseña de tu caso es: {complaint.password}</p>
                        <p className="text">Guarda este código para futuras consultas</p>
                        <div className="row justify-content-end position-absolute" style={{bottom: "10px"}}>
                            <button className="btn btn-primary mx-3" style={{width: "150px"}} onClick={print}>
                                Imprimir
                            </button>
                            <Link to="/" className="btn btn-primary" style={{width: "150px"}}>Volver al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ComplaintsTemplate>
}

export default SuccessPage;