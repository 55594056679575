import {COMPLAINT_STATUS_DICT} from "../Utils/utils";
import {useState} from "react";
import Swal from "sweetalert2";
import HttpClient from "../Utils/HttpClient";

const ComplaintInfo = ({ complaint, isAdmin, isEdit, updateFunc }) => {

    const [status, setStatus] = useState(complaint.status)
    const [message, setMessage] = useState("")

    const editComplaint = () => {
        let client = new HttpClient();
        if (isAdmin){
            client.updateComplaint(complaint._id, { status, message: {message, author: "Admin"} }).then(response => {
                let complaint = response.data.complaint;
                updateFunc(complaint)
                Swal.close()
            }).catch(error => {
                console.log(error)
                Swal.fire({
                    title: "Error",
                    text: "No se pudo actualizar el caso",
                    icon: "error"
                })
            })
        } else {
            client.addMessage(complaint._id, { status, message: {message, author: complaint.name || "Anónimo"} }).then(response => {
                Swal.fire({
                    title: "Éxito",
                    text: "Comentario agregado",
                    icon: "success"
                })
            }).catch(error => {
                console.log(error)
                Swal.fire({
                    title: "Error",
                    text: "No se pudo actualizar el caso",
                    icon: "error"
                })
            })
        }
    }

    return (
        <div className="container">
            <div className="row">
                <h1>Información del caso</h1>
                {complaint.company && <p className="text-start">Empresa: {complaint.company.name}</p>}
                {complaint.name && <p className="text-start">Nombre: {complaint.name}</p>}
                {complaint.email && <p className="text-start">Email: {complaint.email}</p>}
                {complaint.phone && <p className="text-start">Teléfono: {complaint.phone}</p>}
                {complaint.contactTime && <p className="text-start">Horario de contacto: {complaint.contactTime}</p>}
                <p className="text-start">Tipo: {complaint.type}</p>
                <p className="text-start">Unidad de negocio: {complaint.businessUnit}</p>
                <p className="text-start">Fecha de creación: {new Date(complaint.createdAt).toLocaleDateString("es-CL")}</p>
                <p className="text-start">Fecha de actualización: {new Date(complaint.updatedAt).toLocaleDateString("es-CL")}</p>
                {isEdit && isAdmin ?
                    <div className="">
                        <label className="form-label">Estado</label>
                        <select className="form-select" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            {Object.keys(COMPLAINT_STATUS_DICT).map((key, index) => {
                                return <option key={index} value={key}>{COMPLAINT_STATUS_DICT[key]}</option>
                            })}
                        </select>
                    </div> :
                    <p className="text-start">Estado: {COMPLAINT_STATUS_DICT[complaint.status]}</p>
                }
                <p className="text-start">Descripción: {complaint.description}</p>
                {isEdit && (isAdmin || ["pending", "investigating"].includes(complaint.status)) &&
                    <div>
                        <p className="text-start">Comentarios:</p>
                        {complaint.messages.length === 0 && <p className="text-start">No hay comentarios</p>}
                        {
                            complaint.messages.length > 0 && complaint.messages.map((message, index) => {
                                return <div key={index}>
                                    <p className="text-start"><span className="text fw-bold">{message.author}</span>: {message.message}</p>
                                    <p className="text text-end text-muted small">{new Date(complaint.createdAt).toLocaleDateString("es-CL")}</p>
                                </div>
                            })
                        }
                        <div className="form-control">
                            <label className="form-label">Agregar comentario</label>
                            <textarea className="form-control" rows="3" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                        <div className="d-flex justify-content-around mt-3">
                            <button className="btn btn-secondary" onClick={() => {
                                Swal.close()
                            }}>Cancelar</button>
                            <button className="btn btn-success" onClick={editComplaint}>Guardar</button>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default ComplaintInfo;