import ComplaintsTemplate from "../Templates/Complaints.template";
import {ReCAPTCHA} from "react-google-recaptcha";
import DropDownFileComponent from "../Components/DropDownFile.component";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {toFormData} from "axios";
import HttpClient from "../Utils/HttpClient";
import Button from "bootstrap/js/src/button";
import Swal from "sweetalert2";
import {getClientData, getSlugFromURL, StatusEnum} from "../Utils/utils";
import {context} from "../Router/AppRouter.component";
import PersonalInfo from "../Components/PersonalInfo.component";
import SingleSelectionControl from "../Components/SingleSelectionControl.component";

const CreateKarinComplaintPage = (props) => {

    const [selectedAccordion, setSelectedAccordion] = useState(1);
    const [showOtherRelation, setShowOtherRelation] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(true);
    const [data, setData] = useState({
        denunciante: {
            nombre: "",
            rut: "",
            email: "",
            cargo: "",
            unidad: ""
        },
        isVictim: false,
        denunciado: {
            nombre: "",
            rut: "",
            cargo: "",
            unidad: ""
        },
        testigos: []
    });
    const navigate = useNavigate()

    const value = useContext(context);
    let clientData
    let setClientData;
    if (value) {
        clientData = value.clientData;
        setClientData = value.setClientData;
    }

    useEffect(() => {
        document.title = "Sistema de Denuncias";
    }, []);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    }

    const BACKGROUND_COLOR = "#57abdf22";

    const handleFileChange = (files) => {
        setData({
            ...data,
            files
        })
    }

    const validateForm = () => {
        let flag = true;
        console.log(isAnonymous, data);
        if (!isAnonymous){
            if (!data.name || !data.email || !data.phone || !data.contactTime){
                flag = false;
                Swal.fire({
                    title: "Error",
                    text: "Por favor llena todos los campos de información de contacto",
                    icon: "error"
                });
            }
        }

        if (!data.type || !data.businessUnit || (!data.relationship && !data.otherRelation) || !data.description){
            flag = false;
            Swal.fire({
                title: "Error",
                text: "Por favor llena todos los campos de información del caso",
                icon: "error"
            });
        }
        return flag;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // if (!validateForm(event)){
        //     return;
        // }
        let formData = new FormData();

        // if (!isAnonymous) {
        //     formData.append("name", data.name);
        //     formData.append("email", data.email);
        //     formData.append("phone", data.phone);
        //     formData.append("contactTime", data.contactTime);
        // }

        formData.append("company", clientData.name);
        formData.append("data", JSON.stringify(data));

        // formData.append("type", data.type);
        // formData.append("businessUnit", data.businessUnit);
        // formData.append("relationship", data.relationship || data.otherRelation);
        //
        // formData.append("description", data.description);

        if (data.files){
            data.files.forEach((file) => {
                formData.append("files", file);
            });
        }

        let client = new HttpClient();

        client.createGeneralComplaint(formData).then((response) => {
            console.log(response);
            let data = response.data.complaint;
            navigate("/success", {
                state: {
                    ...data
                }
            });

        }).catch((error) => {
            Swal.fire({
                title: "Error",
                text: "Ocurrió un error al crear el caso",
                icon: "error"
            });
            console.error(error);
        })

    }


    return <ComplaintsTemplate state={StatusEnum.SUCCESS}>
        {clientData != null &&
            <div className="row justify-content-center h-100">
                <div className="col-sm-12 col-md-8">
                    <h1 className="title mt-5">Nuevo Caso</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne"
                                            style={selectedAccordion === 1 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(1)}>
                                        Datos de los involucrados
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <PersonalInfo
                                            data={data.denunciante}
                                            setData={(value, key) => {
                                                setData({
                                                    ...data,
                                                    denunciante: {
                                                        ...data.denunciante,
                                                        [key]: value
                                                    }
                                                })
                                            }
                                            }
                                        />
                                        <SingleSelectionControl
                                            question={"Es usted la presunta victima de lo denunciado?"}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.isVictim}
                                            setValue={(value) => {
                                                if (value === "no") {
                                                    setData({
                                                        ...data,
                                                        isVictim: value,
                                                        victim: {
                                                            nombre: "",
                                                            rut: "",
                                                            email: "",
                                                            cargo: "",
                                                            unidad: ""
                                                        }
                                                    })
                                                } else {
                                                    let newsData = {...data};
                                                    delete newsData.victim;
                                                    setData({
                                                        ...newsData,
                                                        isVictim: value
                                                    })
                                                }
                                            }}
                                        />
                                        {data.isVictim && data.isVictim === "no" &&
                                            <div className="mt-3">
                                                <h3> Información de la presunta victima</h3>
                                                <PersonalInfo
                                                    data={data.victim}
                                                    setData={(value, key) => {
                                                        setData({
                                                            ...data,
                                                            victim: {
                                                                ...data.victim,
                                                                [key]: value
                                                            }
                                                        })
                                                    }
                                                    }
                                                />

                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo"
                                            style={selectedAccordion === 2 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(2)}>
                                        Datos del denunciado
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="headingTwo"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <PersonalInfo
                                            data={data.denunciado}
                                            setData={(value, key) => {
                                                setData({
                                                    ...data,
                                                    denunciado: {
                                                        ...data.denunciado,
                                                        [key]: value
                                                    }
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={selectedAccordion === 3 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(3)}>
                                        Situaciones que se denuncian
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <SingleSelectionControl
                                            question={"Acoso Laboral"}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.acoso_laboral}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    acoso_laboral: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"Acoso Sexual"}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.acoso_sexual}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    acoso_sexual: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"Violencia Externa"}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.violencia_externa}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    violencia_externa: value
                                                })
                                            }}
                                        />


                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={selectedAccordion === 4 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(4)}>
                                        Sobre la relación entre víctima y denunciado/a
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <SingleSelectionControl
                                            question={"Existe una relación asimétrica donde la víctima tiene dependencia directa o\n" +
                                                "indirecta de el/la denunciado/a."}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.relacion_victima_dependencia}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    relacion_victima_dependencia: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"Existe una relación asimétrica donde el/la denunciado/a tiene dependencia " +
                                                "directa o indirecta de la víctima."}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.relacion_denunciado_dependencia}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    relacion_denunciado_dependencia: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"Existe una relación simétrica donde el/la denunciado/a y la víctima no" +
                                                " tienen una dependencia directa ni indirecta, pero se desempeñan en la " +
                                                "misma área o equipo."}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.relacion_denunciado_dependencia_misma_area}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    relacion_denunciado_dependencia_misma_area: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"Existe una relación simétrica donde el/la denunciado/a y la víctima no\n" +
                                                "tienen una dependencia directa ni indirecta, y no se desempeñan en la\n" +
                                                "misma área o equipo."}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.relacion_denunciado_dependencia_distinta_area}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    relacion_denunciado_dependencia_distinta_area: value
                                                })
                                            }}
                                        />


                                    </div>
                                </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={selectedAccordion === 5 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(5)}>
                                        Sobre las presuntas situaciones denunciadas
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        <SingleSelectionControl
                                            question={"Existe evidencia de lo denunciado (correos electrónicos, fotos, etc.)"}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.evidencia}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    evidencia: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"Existe conocimiento de otros antecedentes de índole similar."}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.conocimiento_previo}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    conocimiento_previo: value
                                                })
                                            }}
                                        />

                                        <SingleSelectionControl
                                            question={"La situación denunciada fue informada previamente en otra instancia similar\n" +
                                                "(Jefatura, supervisor, mediación laboral, etc.)"}
                                            options={[
                                                {label: "Sí", value: "si"},
                                                {label: "No", value: "no"}
                                            ]}
                                            value={data.denuncia_previa}
                                            setValue={(value) => {
                                                setData({
                                                    ...data,
                                                    denuncia_previa: value
                                                })
                                            }}
                                        />


                                    </div>
                                </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={selectedAccordion === 6 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(6)}>
                                        Información de testigos
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">

                                        {data.testigos.map((datum, index) => {
                                            return (<div>
                                                <PersonalInfo
                                                    data={datum}
                                                    setData={(value, key) => {
                                                        let newTestigos = [...data.testigos];
                                                        newTestigos[index] = {
                                                            ...newTestigos[index],
                                                            [key]: value
                                                        }
                                                        setData({
                                                            ...data,
                                                            testigos: newTestigos
                                                        })
                                                    }}
                                                />
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger mt-3"
                                                        onClick={() => {
                                                            let newTestigos = [...data.testigos];
                                                            newTestigos.splice(index, 1);
                                                            setData({
                                                                ...data,
                                                                testigos: newTestigos
                                                            })
                                                        }}
                                                    >Eliminar Testigo</button>
                                                </div>
                                            </div>)
                                        })
                                        }

                                        <div className="d-flex justify-content-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary mt-3"
                                                onClick={() => {
                                                    setData({
                                                        ...data,
                                                        testigos: [
                                                            ...data.testigos,
                                                            {
                                                                nombre: "",
                                                                rut: "",
                                                                email: "",
                                                                cargo: "",
                                                                unidad: ""
                                                            }
                                                        ]
                                                    })
                                                }}
                                            >Agregar Testigo</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree"
                                            style={selectedAccordion === 7 ? {backgroundColor: BACKGROUND_COLOR} : null}
                                            onClick={() => setSelectedAccordion(7)}>
                                        Detalles
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="form-control my-3">
                                            <label htmlFor="caseDescription">Descripción</label>
                                            <textarea className="form-control" id="caseDescription"
                                                      aria-describedby="caseDescriptionHelp"
                                                      style={{minHeight: "300px"}}
                                                      name="description" value={data.description}
                                                      onChange={handleChange}
                                            />
                                            <small id="caseDescriptionHelp" className="form-text text-muted">Ingresa una
                                                descripción
                                                detallada de tu caso</small>
                                        </div>
                                        {/*<div className="form-control">*/}
                                        {/*    <label htmlFor="">Adjunte archivos que respalden su consulta</label>*/}
                                        {/*    <div className="my-3">*/}
                                        {/*        <DropDownFileComponent onFilesChange={handleFileChange}/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <ReCAPTCHA
                                sitekey="6LdAErYpAAAAAC5pAcvyMAhGgclHv5qFvLKUWiw8"
                            />
                        </div>
                        <div className="row justify-content-end">
                            <button type="submit" className="btn btn-primary mt-3">Crear caso</button>
                        </div>
                    </form>
                </div>
            </div>
        }
    </ComplaintsTemplate>
}

export default CreateKarinComplaintPage;