import axios from 'axios';


class HttpClient {
    client = axios.create({
        baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3030' : 'https://api-denuncias.bwg.cl',
        timeout: 30 * 1000,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    login(email, password) {
        return this.client.post('/api/oauth/login', { email, password });
    }

    register(firstName, lastName, email, password) {
        return this.client.post('/api/oauth/register', { firstName, lastName, email, password });
    }

    logout() {
        return this.client.get('/api/oauth/logout');
    }

    getComplaints() {
        return this.client.get('/api/complaint/');
    }

    updateComplaint(id, data) {
        return this.client.put(`/api/complaint/${id}`, data);
    }

    addMessage(id, data) {
        return this.client.put(`/api/complaint/${id}/message/`, data);
    }

    createComplaint(data) {
        return this.client.post('/api/complaint/', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    createGeneralComplaint(data) {
        return this.client.post('/api/complaint/general/', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getComplaint(code) {
        return this.client.get(`/api/complaint/code/${code}`);
    }

    getCompanies() {
        return this.client.get('/api/company/');
    }

    getCompanyBySlug(slug) {
        return this.client.get(`/api/company/${slug}`);
    }

    createCompany(data) {
        return this.client.post('/api/company/', data);
    }

    updateCompany(id, data) {
        return this.client.put(`/api/company/${id}`, data);
    }

}



export default HttpClient;
