import MainPage from "../Pages/MainPage.component";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import * as PropTypes from "prop-types";
import LoginPage from "../Pages/Admin/LoginPage.component";
import SuccessPage from "../Pages/SuccessPage.component";
import CreateComplaintPage from "../Pages/CreateComplaintPage.component";
import ComplaintsPage from "../Pages/Admin/ComplaintsPage.component";
import CompanyPage from "../Pages/Admin/CompanyPage.component";
import {createContext, useContext, useEffect, useState} from "react";
import HttpClient from "../Utils/HttpClient";
import {CLIENTS_MOCK, DEFAULT_CLIENT, getSlugFromURL} from "../Utils/utils";
import CreateKarinComplaintPage from "../Pages/CreateKarinComplaintPage.component";

function Switch(props) {
    return null;
}

Switch.propTypes = {children: PropTypes.node};

export const context = createContext(null);
const AppRouter = () => {

        const [clientData, setClientData] = useState(null)


        useEffect(() => {
            document.title = "Sistema de Denuncias";
            let slug = getSlugFromURL();

            if (sessionStorage.getItem(slug)) {
                setClientData(JSON.parse(sessionStorage.getItem(slug)));
            } else {
                let client = new HttpClient();
                client.getCompanyBySlug(slug)
                    .then((response) => {
                        let companies = response.data.companies;
                        if (companies.length === 0) {
                            setClientData(CLIENTS_MOCK[DEFAULT_CLIENT]);
                        } else {
                            sessionStorage.setItem(slug, JSON.stringify(companies[0]));
                            setClientData(companies[0]);
                        }
                    }).catch((error) => {
                    console.log(error);
                    setClientData(CLIENTS_MOCK[DEFAULT_CLIENT]);
                })
            }
        }, []);


    return (
        <context.Provider value={{clientData, setClientData}}>
            <BrowserRouter>
                <Routes>
                    <Route index path="/" element={<MainPage />} />
                    <Route path="/complaint" element={<CreateComplaintPage />} />
                    <Route path="/success" element={<SuccessPage />} />


                    <Route index path="/karin/" element={<MainPage />} />
                    <Route index path="/karin/complaint/" element={<CreateKarinComplaintPage />} />
                    <Route path="/karin/success" element={<SuccessPage />} />

                    <Route path="/admin" element={<LoginPage />} />
                    <Route path="/admin/complaints/" element={<ComplaintsPage />}  />
                    <Route path="/admin/companies/" element={<CompanyPage />}  />
                </Routes>
            </BrowserRouter>
        </context.Provider>
    );
}

export default AppRouter;