import ComplaintsTemplate from "../Templates/Complaints.template";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {CLIENTS_MOCK, DEFAULT_CLIENT, getClientData, getSlugFromURL, StatusEnum} from "../Utils/utils";
import {useContext, useEffect, useState} from "react";
import HttpClient from "../Utils/HttpClient";
import withReactContent from "sweetalert2-react-content";
import ComplaintInfo from "../Components/ComplaintInfo.modal";
import {context} from "../Router/AppRouter.component";

const MainPage = (props) => {

    const [password, setPassword] = useState("");
    const [isKarin, setIsKarin] = useState(false);
    const value = useContext(context);
    let clientData
    let setClientData;
    if (value) {
        clientData = value.clientData;
        setClientData = value.setClientData;
    }

    useEffect(() => {
        let url = window.location.href;
        setIsKarin(url.includes("karin"))
    }, [clientData]);

    useEffect(() => {
        if (isKarin) {
            document.title = "Sistema de Denuncias | Ley Karin";
        } else {
            document.title = "Sistema de Denuncias";
        }
    }, [isKarin]);

    const displayComplaintInfo = () => {
        let client = new HttpClient();
        if (!password) {
            Swal.fire({
                title: "Error",
                text: "Debes ingresar el código de caso",
                icon: "error"
            });
            return;
        }
        client.getComplaint(password).then(response => {
            let data = response.data.complaint;
            if (data == null) {
                Swal.fire({
                    title: "Error",
                    text: "No se encontró el caso",
                    icon: "error"
                });
            } else {
                withReactContent(Swal).fire({
                    title: "Información",
                    html: <ComplaintInfo complaint={data} isEdit={true} isAdmin={false}/>,
                    icon: "info"
                });
            }
            setPassword("")
        }).catch(error => {
            console.log(error)
        })
    }

    console.log("main", clientData)

    return <ComplaintsTemplate>
        {clientData != null &&
        <div className="row justify-content-center h-100 align-content-center">
            <div className="row mt-5 mb-3">
                <h1 className="title text-center"> Bienvenido al sistema de consultas/denuncias de {clientData.name}</h1>
                <p className="text-center text-black">Nos enorgullece fortalecer los valores éticos impresos en nuestra cultura nos definen al momento de actuar.
                    Con el objeto de mantener altos estándares de integridad en los negocios, hemos dispuesto a nuestros colaboradores, ejecutivos, directores de la compañía y de terceros el presente Canal de Integridad para que, de manera confidencial y anónima, realicen consultas sobre materias éticas o informen de infracciones o transgresiones a cualquier ley o normativa vigente , así como transgresiones a las normas internas de la compañía respectiva, o a los principios o valores establecidos en el Código de Integridad.
                    <br/>
                    Finalmente, confiamos en el valor de la verdad y el respeto, por lo que le solicitamos usar este Canal con responsabilidad. Gracias por confiar en nosotros.</p>
            </div>
            <div className="col-sm-12 col-md-4">
                <div className="card" style={{minHeight: "250px"}}>
                    <div  className="card-header">
                        <span className="text card-title">Consulta tu caso</span>
                    </div>
                    <div className="card-body position-relative" >
                        <form>
                            <div className="form-group">
                                <label htmlFor="caseNumber">Código de caso</label>
                                <input type="text" className="form-control" id="caseNumber"
                                       aria-describedby="caseNumberHelp" value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                />
                                <small id="caseNumberHelp" className="form-text text-muted">Ingresa el código de caso
                                    que te proporcionaron</small>
                            </div>
                            <button onClick={displayComplaintInfo} type="button" className="btn btn-primary position-absolute" style={{bottom: "15px"}}>Consultar</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4">
                <div className="card" style={{minHeight: "250px"}}>
                    <div className="card-header">
                        <span className="text card-title">Crea un caso nuevo</span>
                    </div>
                    <div className="card-body position-relative">
                        <div className="form-group p-1">
                            <small className="form-text text-muted">
                                Crear un caso nuevo te permitirá realizar una denuncia o consulta sobre un tema específico que desees reportar.
                            </small>
                        </div>
                        <Link to={ isKarin ? "/karin/complaint" : "/complaint"} type="submit" className="btn btn-primary position-absolute"
                                style={{bottom: "15px"}}>Crear caso
                        </Link>
                    </div>
                </div>
            </div>
        </div>}
    </ComplaintsTemplate>
}

export default MainPage;